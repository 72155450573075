<template>
  <div class="container p-0">
    <div
      class="d-flex justify-content-between align-items-center flex-wrap"
      v-if="!formId"
    >
      <div class="px-0 col-12 col-sm-9 mb-3 mb-sm-0" style="max-width: 500px">
        <select-control
          v-model="formType"
          :options="formTypes"
          type="vertical"
          wrapClass="mb-0"
          inputClass="light-shadow"
        >
        </select-control>
      </div>
      <button
        class="btn btn-black"
        @click="formType && openIntakeModal()"
        :disabled="createIntakeLoading || formType == 0"
      >
        <i class="fas fa-spin fa-circle-notch" v-if="createIntakeLoading"></i>
        <span v-else> Create Intake </span>
      </button>
    </div>
    <alert v-if="waiting && !timerActive" class="mt-3" color="secondary"
      >Processing...</alert
    >
    <alert v-if="waiting && timerActive" class="mt-3" color="secondary"
      >You will be auto redirected in {{ countdown }} seconds</alert
    >
    <div
      ref="iframeWrapper"
      class="
        position-relative
        mt-4
        shadow-card
        rounded
        bg-white
        intake-container
      "
    >
      <span @click="toggleFullscreen" v-if="intakeUrl">
        <icon
          type="expand-arrows-alt"
          class="fullscreen-element expand"
          v-show="!isFullscreen"
        />
        <icon
          type="compress-arrows-alt"
          class="fullscreen-element compress"
          v-show="isFullscreen"
        />
      </span>
      <iframe
        v-if="intakeUrl"
        width="100%"
        height="100%"
        :src="intakeUrl"
        frameborder="0"
        allowfullscreen
        webkitallowfullscreen
        mozallowfullscreen
      >
        Your browser doesn't support iframes
      </iframe>
      <div
        class="d-flex justify-content-center align-items-center h-100 w-100 p-3"
      >
        <i
          class="fas fa-spin fa-circle-notch fa-2x"
          v-if="createIntakeLoading"
        ></i>
        <span v-if="!createIntakeLoading && !waiting" class="h3 text-center">
          No intake form active, use the button above to create new one.
        </span>
      </div>
    </div>

    <modal
      class="intake-form-modal"
      name="intake-form-modal"
      transition="pop-out"
      :clickToClose="false"
      height="auto"
    >
      <div class="position-relative main-modal-container">
        <div class="vm--top-right-slot border-bottom pb-3" style="z-index: 1">
          <h2
            class="
              position-absolute
              m-3
              pt-1
              mr-5
              text-left
              font-weight-bold
              pointer-none
            "
            style="letter-spacing: 0.1px"
          >
            New Intake
          </h2>
          <button
            class="btn p-0 pr-3 pt-3 border-0"
            :disabled="createIntakeLoading || formType == 0"
            @click="closeIntakeModal"
          >
            <i class="fas fa-times fa-lg"></i>
          </button>
        </div>
        <div class="card-body mt-4">
          <div class="row border-bottom pb-4">
            <input-control
              v-model="(patientData.user || {}).phone_number"
              type="horizontal"
              labelClass="phone-label"
              wrapClass="align-items-center my-0"
              :cleave="cleave.phone"
              >Phone Number</input-control
            >
          </div>
          <div class="saving-note-container" v-if="createIntakeLoading">
            <div
              class="saving-note alert alert-info mb-1 mt-3 py-1"
              role="alert"
            >
              This may take few moments...
            </div>
          </div>
          <div class="custom-control custom-checkbox mt-3">
            <input
              type="checkbox"
              class="custom-control-input"
              id="open_intake"
              v-model="openIntake"
            />
            <label class="custom-control-label mr-3 mb-3" for="open_intake"
              >Open Intake</label
            >
          </div>

          <div class="mb-2 mt-4 d-flex justify-content-end">
            <save
              :shouldDisable="createIntakeLoading"
              iconType="envelope"
              @click="createIntakeHandler('email')"
              classes="btn btn-black mr-4"
              >Send Email</save
            >
            <save
              :shouldDisable="createIntakeLoading"
              iconType="message"
              @click="createIntakeHandler('sms')"
              classes="btn btn-black mr-4"
              >Send SMS</save
            >
            <save
              :shouldDisable="createIntakeLoading"
              iconType="copy"
              @click="createIntakeHandler('copy')"
              classes="btn btn-theme"
              >Get Intake URL</save
            >
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import Swal from "sweetalert2";

export default {
  name: "CreateIntakeForm",
  data() {
    return {
      createIntakeLoading: false,
      intakeUrl: "",
      isFullscreen: false,
      openIntake: false,
      waiting: false,
      timerActive: false,
      countdown: 10,
      intake: null,
      formType: 0,
      cleave: {
        phone: { blocks: [20], numericOnly: true },
      },
    };
  },
  created() {
    this.getIntakeCategories({ status: "ACTIVE" });
    if (this.formId) {
      this.completeIntakeHandler();
    }

    window.addEventListener("message", this.firsthx_iframe_message);
    document.addEventListener("fullscreenchange", () => {
      document.fullscreenElement
        ? (this.isFullscreen = true)
        : (this.isFullscreen = false);
    });
  },
  beforeDestroy() {
    // Clear interval when component is destroyed to avoid memory leaks
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
  destroyed() {
    window.removeEventListener("message", this.firsthx_iframe_message);
    document.removeEventListener("fullscreenchange", () => {
      document.fullscreenElement
        ? (this.isFullscreen = true)
        : (this.isFullscreen = false);
    });
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      patient: (state) => state.patients.patient,
      categories: (state) => state.intakeForms.categories.data,
    }),
    formId: function () {
      return this.$route.params.formId;
    },
    patientData: function () {
      return JSON.parse(JSON.stringify(this.patient));
    },
    formTypes: function () {
      return [
        { value: 0, label: "Select Form Type" },
        ...this.categories.map((item) => {
          return {
            value: item.type_id,
            label: item.name,
          };
        }),
      ];
    },
  },
  methods: {
    ...mapActions({
      createIntakeForm: "intakeForms/create",
      getIntakeForm: "intakeForms/getIntakeById",
      updateIntake: "intakeForms/update",
      getIntakeCategories: "intakeForms/getCategories",
    }),
    closeIntakeModal: function () {
      this.$modal.hide("intake-form-modal");
    },
    openIntakeModal: function () {
      if (this.user && (this.user.isAdmin || this.user.isManagingAdmin)) {
        this.openIntake = false;
        this.$modal.show("intake-form-modal");
      }
    },
    createIntakeHandler: async function (sendType) {
      if (sendType == "sms" && !(this.patientData.user || {}).phone_number) {
        return Swal.fire({
          title: "Error",
          text: "Please fill out the phone number",
          icon: "error",
        });
      }
      this.createIntakeLoading = true;
      try {
        const data = await this.createIntakeForm({
          userId: this.patient.user_id,
          type: this.formType,
          phoneNumber: this.patientData.user.phone_number,
          sendType,
          domain: `${window.location.host}/intake`,
        });
        this.intake = data?.data;
        this.intakeUrl = data?.data?.form_url || "";
        this.createIntakeLoading = false;
        if (sendType == "copy") {
          const fullLink = `https://${window.location.host}/intake/${data?.data?.mkey}`;
          navigator.clipboard.writeText(fullLink).then(
            function () {
              Swal.fire({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 5000,
                timerProgressBar: true,
                icon: "success",
                title:
                  "The intake form link has been copied to your clipboard.",
              });
            },
            function (err) {
              console.error("Async: Could not copy text: ", err);
            }
          );
        }
        this.closeIntakeModal();
        if (!this.openIntake) {
          this.$router.replace({ name: "patients.intakeForms" });
        }
      } catch (_) {
        this.createIntakeLoading = false;
      }
    },
    completeIntakeHandler: async function () {
      this.createIntakeLoading = true;
      try {
        const data = await this.getIntakeForm(this.formId);
        this.intake = data;
        this.intakeUrl = data?.form_url || "";
        this.createIntakeLoading = false;
      } catch (error) {
        console.log(error);
        this.createIntakeLoading = false;
      }
    },
    toggleFullscreen() {
      this.isFullscreen = !this.isFullscreen;
      const iframeWrapper = this.$refs.iframeWrapper;
      if (this.isFullscreen) {
        if (iframeWrapper.requestFullscreen) {
          iframeWrapper.requestFullscreen();
        } else if (iframeWrapper.mozRequestFullScreen) {
          iframeWrapper.mozRequestFullScreen(); // Firefox
        } else if (iframeWrapper.webkitRequestFullscreen) {
          iframeWrapper.webkitRequestFullscreen(); // Chrome, Safari, Opera
        } else if (iframeWrapper.msRequestFullscreen) {
          iframeWrapper.msRequestFullscreen(); // IE/Edge
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen(); // Firefox
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen(); // Chrome, Safari, Opera
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen(); // IE/Edge
        }
      }
    },
    startCountdown() {
      this.timerActive = true;
      this.countdown = 10; // Reset the countdown to 10 seconds

      this.intervalId = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          clearInterval(this.intervalId); // Stop the timer when countdown reaches 0
          this.timerActive = false;
        }
      }, 1000); // Update the countdown every 1 second (1000 ms)
    },
    async firsthx_iframe_message(event) {
      if (event.data === "Activity" && this.intake?.status == "created") {
        const data = await this.updateIntake(this.intake.id);
        if (data) {
          this.intake = data;
        }
      }
      if (
        (event.data === "Completed" || event.data === "IntakeEnded") &&
        !this.timerActive
      ) {
        this.waiting = true;
        try {
          const data = await this.updateIntake(this.intake.id);
          if (data) {
            this.startCountdown();
            setTimeout(() => {
              this.$router.replace({ name: "patients.intakeForms" });
            }, 10000);
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.intake-container {
  height: 80vh;
  background: hsla(186, 33%, 94%, 1);
  background: linear-gradient(
    90deg,
    hsla(186, 33%, 94%, 1) 0%,
    hsla(216, 41%, 79%, 1) 100%
  );
  background: -moz-linear-gradient(
    90deg,
    hsla(186, 33%, 94%, 1) 0%,
    hsla(216, 41%, 79%, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    hsla(186, 33%, 94%, 1) 0%,
    hsla(216, 41%, 79%, 1) 100%
  );
  overflow: hidden;

  .fullscreen-element {
    right: 8px;
    top: 8px;
    font-size: 2rem;
    color: #000;
    transition: transform 0.3s;
    cursor: pointer;

    &:hover {
      transform: scale(1.1);
    }
  }

  .expand {
    position: absolute !important;
  }

  .compress {
    position: fixed !important;
    z-index: 2147483647 !important;
  }
}
</style>
<style lang="scss">
.intake-form-modal {
  .main-modal-container {
    .phone-label {
      max-width: 150px;
      flex: 0 0 150px;
    }
  }

  .v--modal-box {
    overflow: visible;
    max-height: 100%;
    transition: none;

    @media (max-width: 768px) {
      max-height: 96vh;
      overflow: hidden;

      .card-body {
        max-height: calc(96vh - 47px);
        overflow-y: auto;
      }
    }
  }
}
</style>
